<template>
  <b-card>
    <b-col class="form-container">
      <h4 class="mb-2">Zamówienie nr {{ this.$route.params.id }}</h4>

      <b-form-group label="Status">
        <b-form-input :value="status" disabled />
      </b-form-group>

      <b-form-group label="TETA status">
        <b-form-input :value="`Zamówienie #${order.tetaOrderNumber}`" disabled />
      </b-form-group>

      <b-form-group label="Wartość">
        <b-form-input :value="`${order.total_price ? order.total_price.toFixed(2) : 0} PLN`" disabled />
      </b-form-group>

      <b-form-group label="Wartość dostawy">
        <b-form-input :value="`${order.delivery_price ? order.delivery_price.toFixed(2) : 0} PLN`" disabled />
      </b-form-group>

      <b-form-group label="Wartość produktów">
        <b-form-input :value="`${order.products_price ? order.products_price.toFixed(2) : 0} PLN`" disabled />
      </b-form-group>

      <b-form-group label="Wykorzystano kod rabatowy?">
        <b-form-input :value="`${order.discount_code ? 'TAK' : 'NIE'}`" disabled />
      </b-form-group>

      <b-form-group v-if="order.discount_code" label="Kod rabatowy">
        <b-form-input :value="order.discount_code" disabled />
      </b-form-group>

      <b-form-group v-if="order.discount_code" label="Rodzaj kodu rabatowego">
        <b-form-input :value="discountCodeType" disabled />
      </b-form-group>

      <b-form-group v-if="order.discount_code" label="Wartość kodu rabatowego">
        <b-form-input
          :value="`${order.discount_code_value} ${
            ['delivery', 'percent', 'newsletter'].includes(order.discount_code_type) ? '%' : 'PLN'
          }`"
          disabled />
      </b-form-group>

      <h4 class="mt-4 mb-2">Zamawiający</h4>

      <b-form-group label="Email">
        <b-form-input :value="order.orderData.email" disabled />
      </b-form-group>

      <b-form-group label="Telefon">
        <b-form-input :value="order.orderData.phone" disabled />
      </b-form-group>

      <b-form-group label="Imię">
        <b-form-input :value="order.orderData.name" disabled />
      </b-form-group>

      <b-form-group label="Nazwisko">
        <b-form-input :value="order.orderData.surname" disabled />
      </b-form-group>

      <b-form-group label="Data zamówienia">
        <b-form-input :value="order.created_at" disabled />
      </b-form-group>

      <h4 class="mt-4 mb-2">Dane adresowe</h4>

      <b-form-group label="Adres">
        <b-form-input :value="order.orderData.address" disabled />
      </b-form-group>

      <b-form-group label="Miasto">
        <b-form-input :value="cityAddress" disabled />
      </b-form-group>

      <b-form-group label="Państwo">
        <b-form-input :value="order.orderData.country" disabled />
      </b-form-group>

      <h4 class="mt-4 mb-2">Adres dostawy</h4>

      <b-form-group label="Inny adres dostawy?">
        <b-form-input :value="order.orderData.different_delivery_address ? 'TAK' : 'NIE'" disabled />
      </b-form-group>

      <b-form-group label="Dostawa InPost?">
        <b-form-input :value="order.orderData.inpost_name ? 'TAK' : 'NIE'" disabled />
      </b-form-group>

      <div v-if="order.orderData.different_delivery_address && !order.orderData.inpost_name">
        <b-form-group v-if="order.orderData.different_delivery_address" label="Adres">
          <b-form-input :value="order.orderData.delivery_address" disabled />
        </b-form-group>

        <b-form-group v-if="order.orderData.different_delivery_address" label="Miasto">
          <b-form-input :value="deliveryCityAddress" disabled />
        </b-form-group>

        <b-form-group v-if="order.orderData.different_delivery_address" label="Państwo">
          <b-form-input :value="order.orderData.delivery_country" disabled />
        </b-form-group>
      </div>

      <div v-if="order.orderData.inpost_name">
        <b-form-group v-if="order.orderData.inpost_name" label="InPost ID">
          <b-form-input :value="order.orderData.inpost_name" disabled />
        </b-form-group>

        <b-form-group v-if="order.orderData.inpost_agency" label="InPost agencja">
          <b-form-input :value="order.orderData.inpost_agency" disabled />
        </b-form-group>

        <b-form-group v-if="order.orderData.inpost_street" label="InPost ulica">
          <b-form-input :value="order.orderData.inpost_street" disabled />
        </b-form-group>

        <b-form-group v-if="order.orderData.inpost_building_number" label="InPost numer budynku">
          <b-form-input :value="order.orderData.inpost_building_number" disabled />
        </b-form-group>

        <b-form-group v-if="order.orderData.inpost_flat_number" label="InPost numer mieszkania">
          <b-form-input :value="order.orderData.inpost_flat_number" disabled />
        </b-form-group>

        <b-form-group v-if="order.orderData.inpost_zip_code" label="InPost kod pocztowy">
          <b-form-input :value="order.orderData.inpost_zip_code" disabled />
        </b-form-group>

        <b-form-group v-if="order.orderData.inpost_city" label="InPost miasto">
          <b-form-input :value="order.orderData.inpost_city" disabled />
        </b-form-group>

        <b-form-group v-if="order.orderData.inpost_province" label="InPost area">
          <b-form-input :value="order.orderData.inpost_province" disabled />
        </b-form-group>
      </div>

      <h4 class="mt-4 mb-2">Dane firmy</h4>

      <b-form-group label="Potrzebuje FV VAT?">
        <b-form-input :value="order.orderData.is_vat ? 'TAK' : 'NIE'" disabled />
      </b-form-group>

      <b-form-group label="Nazwa firmy">
        <b-form-input :value="order.orderData.company_name" disabled />
      </b-form-group>

      <b-form-group label="NIP">
        <b-form-input :value="order.orderData.nip" disabled />
      </b-form-group>

      <h4 class="mt-4 mb-2">Dostawa i płatność</h4>

      <b-form-group label="Metoda dostawy">
        <b-form-input :value="order.delivery_method" disabled />
      </b-form-group>

      <b-form-group label="Metoda płatności">
        <b-form-input :value="order.payment_method" disabled />
      </b-form-group>

      <b-form-group v-if="order.payment_method === 'PayU'" label="PayU ID">
        <b-form-input :value="order.payu_order_id" disabled />
      </b-form-group>

      <h4 class="mt-4 mb-2">Produkty</h4>

      <div v-for="(product, index) in order.products" :key="product.id">
        <h6 class="mt-6 mb-2">Produkt {{ index + 1 }}</h6>

        <b-form-group label="Nazwa">
          <b-form-input :value="product.name" disabled />
        </b-form-group>

        <b-form-group label="Symbol indeksu">
          <b-form-input :value="product.sku" disabled />
        </b-form-group>

        <b-form-group label="Cena jednostkowa">
          <b-form-input :value="`${product.unit_price} PLN`" disabled />
        </b-form-group>

        <b-form-group label="Ilość">
          <b-form-input :value="product.quantity" disabled />
        </b-form-group>

        <!-- DIVIDER -->
        <div class="mt-1 mb-2">
          <hr />
        </div>
      </div>
    </b-col>
  </b-card>
</template>

<script>
import { show, pay } from '@/http/orders';

import NotificationMixin from '@/mixins/NotificationMixin';

export default {
  mixins: [NotificationMixin],

  data() {
    return {
      order: {},
    };
  },

  created() {
    this.fetchOrder();
  },

  computed: {
    cityAddress() {
      return `${this.order.orderData.zip_code} ${this.order.orderData.city}`;
    },
    deliveryCityAddress() {
      return `${this.order.orderData.delivery_zip_code} ${this.order.orderData.delivery_city}`;
    },
    canSetStatus() {
      return this.order.status === 'new' && this.order.payment_method !== 'PayU';
    },
    discountCodeType() {
      switch (this.order.discount_code_type) {
        case 'percent':
          return 'Procentowy';

        case 'amount':
          return 'Kwotowy';

        case 'delivery':
          return 'Na dostawę';

        case 'newsletter':
          return 'Newsletter';

        default:
          return 'Nieznany';
      }
    },
    status() {
      switch (this.order.status) {
        case 'new':
          return 'Nowe';

        case 'pending':
          return 'Oczekujące';

        case 'cancelled':
          return 'Anulowane';

        case 'completed':
          return 'Opłacone';
      }
    },
  },

  methods: {
    async fetchOrder() {
      try {
        const { data } = await show(this.$route.params.id);

        this.order = data.data;
      } catch (error) {
        this.$toast.error({
          title: 'Błąd',
          message: 'Nie udało się pobrać danych',
        });
      }
    },
  },
};
</script>
