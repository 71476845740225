import axios from '../axios';

export const all = () => {
  return axios.get('/orders/all', {
    params: {
      lang: 'pl',
    },
  });
};

export const show = (id) => {
  return axios.get(`/orders/${id}`, {
    params: {
      lang: 'pl',
    },
  });
};

export const pay = (id) => {
  return axios.post(`/orders/${id}/pay`);
};
